
$(() => {
  // auto-toggle
  $('.auto-toggle').each(function() {
    const el = $(this);
    const input = $(el.data('input'));
    let values  = el.data('value');
    if (values && values.indexOf && values.indexOf(',') > -1) {
      values = values.split(',');
    } else {
      values = [ String(values) ];
    }
    const autotoggle = () => {
      let val = input.val();
      if (input.attr('type') === 'checkbox') {
        val = String(input.is(':checked'));
      }
      if (input.attr('type') === 'radio') {
        val = $(el.data('input') + ':checked').val();
      }
      const visible = values.indexOf(val) > -1;
      el.toggle(visible);
    };
    input.on('change', autotoggle);
    autotoggle();
  });
});
